import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAFTG0VWc_6O1vXq7hNTJ0Gbljw97-GmVI",
    authDomain: "tehovnik-firebase.firebaseapp.com",
    projectId: "tehovnik-firebase",
    storageBucket: "tehovnik-firebase.appspot.com",
    messagingSenderId: "682583926670",
    appId: "1:682583926670:web:090f9e92600a9a362126df"
  };

export const app = initializeApp(firebaseConfig);