/* --- NPM --- */
import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";

/* Components */
import Sidebar from './components/Sidebar';
import WithAuth from './auth/Authentication'
import Login from './components/Login'

/* Pages */
import Novice from './pages/Novice';
import Stroji from './pages/Stroji';

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false
    }
    this.openSidebar = this.openSidebar.bind(this);
  }

  openSidebar() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  render() {

    return (
      <>
        <BrowserRouter>

          <Routes>
            <Route path="/login" element={<WithAuth><Login /></WithAuth>} />

            <Route path="/novice" element={
              <>
                <Sidebar
                  open={this.state.sidebarOpen}
                  openSidebar={this.openSidebar}
                />
                <Novice open={this.state.sidebarOpen} />
              </>

            } />
            <Route path="/stroji" element={
              <>
                <Sidebar
                  open={this.state.sidebarOpen}
                  openSidebar={this.openSidebar}
                />
                <Stroji open={this.state.sidebarOpen} />
              </>

            } />
            <Route path="*" element={

              <WithAuth>
                <Login />
              </WithAuth>
            }
            />
          </Routes>
        </BrowserRouter>
      </>
    )
  }
}
