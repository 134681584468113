/* --- NPM --- */
import React, { Component } from 'react';
import Select from '../components/Select'
import { app } from '../firebase/firebase-config'
import { collection, doc, deleteDoc, getDocs, addDoc, updateDoc, getFirestore } from "firebase/firestore";
const db = getFirestore(app);

const navbar = [
  { name: "Ozobljenje", category: "K1" },
  { name: "Struženje in rezkanje", category: "K2" },
  { name: "Brušenje", category: "K3" },
  { name: "Pehanje", category: "K4" },
  { name: "Razrez", category: "K5" },
]

export default class Stroji extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popup: false,
      open: false,
      popupEdit: false,
      selectedKategorija: {},
      novStrojselectedCategory: "K1",
      editCategory: "K1",
      imeStroja: "",
      imeStrojaEdit: "",
      editId: "",
      errorMsg: "",
      stroji: []
    }

    this.novStroj = this.novStroj.bind(this)
    this.getStroji = this.getStroji.bind(this)
    this.deleteStroj = this.deleteStroj.bind(this)
    this.urediStroj = this.urediStroj.bind(this)

    this.changeValue = this.changeValue.bind(this)
    this.changeSelected = this.changeSelected.bind(this)
    this.changeSelected2 = this.changeSelected2.bind(this)
  }

  componentDidMount() {
    this.getStroji({ name: "Ozobljenje", category: "K1" })
  }



  changeValue(e) {
    if (e.target.files) {
      this.setState({
        uploadImage: e.target.files[0]
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  async getStroji(kategorija) {
    const querySnapshot = await getDocs(collection(db, "stroji"));
    let strojiArray = []
    querySnapshot.forEach((doc) => {
      strojiArray.push({ name: doc.data().name, category: doc.data().category, id: doc.id })
    });
    this.setState({ stroji: strojiArray, selectedKategorija: kategorija, editCategory: kategorija.category })
  }

  async novStroj() {
    const category = this.state.novStrojselectedCategory
    const name = this.state.imeStroja

    await addDoc(collection(db, "stroji"), {
      category: category,
      name: name
    });

    switch (this.state.novStrojselectedCategory) {
      case "K1":
        this.getStroji({ name: "Ozobljenje", category: "K1" })
        break;
      case "K2":
        this.getStroji({ name: "Struženje in rezkanje", category: "K2" })
        break;
      case "K3":
        this.getStroji({ name: "Brušenje", category: "K3" })
        break;
      case "K4":
        this.getStroji({ name: "Pehanje", category: "K4" })
        break;
      case "K5":
        this.getStroji({ name: "Razrez", category: "K5" })
        break;
      default:
        this.getStroji({ name: "Ozobljenje", category: "K1" })
        break;
    }
  }

  async deleteStroj(stroj) {
    await deleteDoc(doc(db, "stroji", stroj.id));
    switch (stroj.category) {
      case "K1":
        this.getStroji({ name: "Ozobljenje", category: "K1" })
        break;
      case "K2":
        this.getStroji({ name: "Struženje in rezkanje", category: "K2" })
        break;
      case "K3":
        this.getStroji({ name: "Brušenje", category: "K3" })
        break;
      case "K4":
        this.getStroji({ name: "Pehanje", category: "K4" })
        break;
      case "K5":
        this.getStroji({ name: "Razrez", category: "K5" })
        break;
      default:
        this.getStroji({ name: "Ozobljenje", category: "K1" })
        break;
    }

  }

  async urediStroj() {
    const docRef = doc(db, 'stroji', this.state.editId);
    await updateDoc(docRef, {
      name: this.state.imeStrojaEdit,
      category: this.state.novStrojselectedCategory
    });
    this.setState({ popupEdit: false, editCategory: this.state.novStrojselectedCategory })
    switch (this.state.novStrojselectedCategory) {
      case "K1":
        this.getStroji({ name: "Ozobljenje", category: "K1" })
        break;
      case "K2":
        this.getStroji({ name: "Struženje in rezkanje", category: "K2" })
        break;
      case "K3":
        this.getStroji({ name: "Brušenje", category: "K3" })
        break;
      case "K4":
        this.getStroji({ name: "Pehanje", category: "K4" })
        break;
      case "K5":
        this.getStroji({ name: "Razrez", category: "K5" })
        break;
      default:
        this.getStroji({ name: "Ozobljenje", category: "K1" })
        break;
    }
  }

  changeSelected(selected) {
    this.setState({ selectedKategorija: selected })
  }

  changeSelected2(selected) {
    this.setState({ novStrojselectedCategory: selected.category })
  }

  render() {

    return (
      <>
        <div className={this.state.popup ? "block absolute w-screen h-screen z-30" : "hidden"}>
          <div className="bg-gray-800 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-white px-10 py-10 rounded min-w-[400px]">
            <p className="mt-3 mb-1 text-xs">KATEGORIJA</p>
            <Select items={navbar} changeSelectedProps={this.changeSelected2} selectedCategory={"K1"} />
            <p className="mt-3 mb-1 text-xs">IME STROJA</p>
            <input className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="imeStroja" value={this.state.imeStroja} onChange={this.changeValue} />

            <div className="flex mt-10 justify-between">
              <button className="bg-green-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={this.novStroj}>Nov stroj</button>
              <button className="bg-red-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.setState({ popup: !this.state.popup })}>Prekliči</button>
            </div>
            {this.state.errorMsg && <div className="mt-3 text-sm">{this.state.errorMsg}</div>}
          </div>
          <div className="w-screen h-screen z-10 bg-gray-900 opacity-90" onClick={() => this.setState({ popup: !this.state.popup })} />
        </div>

        <div className={this.state.popupEdit ? "block absolute w-screen h-screen z-30" : "hidden"}>
          <div className="bg-gray-800 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-white px-10 py-10 rounded min-w-[400px]">
            <p className="mt-3 mb-1 text-xs">KATEGORIJA</p>
            {this.state.popupEdit && <Select items={navbar} changeSelectedProps={this.changeSelected2} selectedCategory={this.state.editCategory} />}
            <p className="mt-3 mb-1 text-xs">IME STROJA</p>
            <input className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="imeStrojaEdit" value={this.state.imeStrojaEdit} onChange={this.changeValue} />

            <div className="flex mt-10 justify-between">
              <button className="bg-green-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={this.urediStroj}>Uredi stroj</button>
              <button className="bg-red-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.setState({ popupEdit: !this.state.popupEdit })}>Prekliči</button>
            </div>
            {this.state.errorMsg && <div className="mt-3 text-sm">{this.state.errorMsg}</div>}
          </div>
          <div className="w-screen h-screen z-10 bg-gray-900 opacity-90" onClick={() => this.setState({ popupEdit: !this.state.popupEdit })} />
        </div>


        <div className={`${this.props.open ? "ml-48 w-[calc(12rem - 100vw)]" : "ml-10 w-[calc(2.5rem - 100vw)]"} md:ml-48 relative bg-gray-600 w-[calc(12rem - 100vw)] h-screen text-white overflow-auto`}>
          <div className="w-full h-10 bg-gray-800 relative flex justify-between items-center">
            <p className="text-xl ml-5">Stroji</p>
            <button className="mr-5 bg-sky-900 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.setState({ popup: !this.state.popup })}>Nov stroj</button>
          </div>
          <div className="relative">
            <div className="ml-5 mt-5">Kategorija:</div>
            <div className="m-5 mt-0 w-auto md:w-1/3">
              <Select items={navbar} changeSelectedProps={this.changeSelected} selectedCategory={this.state.editCategory} />
            </div>
            {this.state.stroji.map(stroj => {
              if (this.state.selectedKategorija.category === stroj.category) {
                return (
                  <div key={stroj.ime} className="bg-gray-700 m-5 p-5 rounded-md flex flex-row justify-between">

                    <div className="flex flex-col justify-evenly">
                      <p>Ime: <span className="font-bold">{stroj.name}</span></p>
                      <p>Kategorija: <span className="font-bold">{this.state.selectedKategorija.name}</span></p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <button className="bg-green-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.setState({ popupEdit: !this.state.popupEdit, imeStrojaEdit: stroj.name, editCategory: stroj.category, editId: stroj.id })}>Uredi stroj</button>
                      <button className="bg-red-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.deleteStroj(stroj)}>Izbriši stroj</button>
                    </div>

                  </div>
                )
              } else {
                return (null)
              }

            })}
          </div>
        </div>
      </>
    )
  }
}
