/* --- NPM --- */
import React, { Component } from 'react';
import { app } from '../firebase/firebase-config'
import { collection, doc, deleteDoc, getDocs, addDoc, updateDoc, getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
const db = getFirestore(app);

export default class Novice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popup: false,
      open: false,
      popupEdit: false,
      editId: "",
      errorMsg: "",
      editImageURL: "",
      editImageName: "",
      editUploadImage: null,
      uploadImage: null,
      novice: [],
      titleSloEdit: "",
      titleAngEdit: "",
      titleNemEdit: "",
      textSloEdit: "",
      textAngEdit: "",
      textNemEdit: "",
      titleSlo: "",
      titleAng: "",
      titleNem: "",
      textSlo: "",
      textAng: "",
      textNem: "",
    }

    this.novaNovica = this.novaNovica.bind(this)
    this.getNovice = this.getNovice.bind(this)
    this.deleteNovica = this.deleteNovica.bind(this)
    this.urediNovica = this.urediNovica.bind(this)

    this.changeValue = this.changeValue.bind(this)
  }

  componentDidMount() {
    this.getNovice()
  }



  changeValue(e) {
    if (e.target.files) {
      this.setState({
        uploadImage: e.target.files[0]
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  async getNovice() {
    const querySnapshot = await getDocs(collection(db, "novice"));
    let noviceArray = []
    querySnapshot.forEach((doc) => {
      noviceArray.push({
        id: doc.id,
        date: doc.data().date,
        image: doc.data().image,
        imageName: doc.data().imageName,
        text_de: doc.data().text_de,
        text_si: doc.data().text_si,
        text_en: doc.data().text_en,
        title_de: doc.data().title_de,
        title_si: doc.data().title_si,
        title_en: doc.data().title_en,
      })
    });
    this.setState({ novice: noviceArray, open: false })
  }

  async novaNovica() {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    result += ".png"
    const storage = getStorage();
    const storageRef = ref(storage, result);
    const metadata = {
      contentType: 'image/jpeg'
    };
    const uploadTask = uploadBytesResumable(storageRef, this.state.uploadImage, metadata);

    uploadTask.on('state_changed',
      (snapshot) => {

        /* const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100; */

      },
      (error) => {
        console.log(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const {
            titleSlo,
            titleAng,
            titleNem,
            textSlo,
            textAng,
            textNem
          } = this.state

          addDoc(collection(db, "novice"), {
            image: downloadURL,
            imageName: result,
            date: serverTimestamp(),
            text_de: textNem,
            text_en: textAng,
            text_si: textSlo,
            title_de: titleNem,
            title_en: titleAng,
            title_si: titleSlo
          });
          setTimeout(() => {
            this.getNovice()
            this.setState({ uploadImage: null })
          }, 2000)

        });
      }
    );






  }

  async deleteNovica(novica) {

    await deleteDoc(doc(db, "novice", novica.id));
    const storage = getStorage();
    const desertRef = ref(storage, novica.imageName);
    deleteObject(desertRef).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
    this.getNovice()
  }

  async urediNovica() {
    const docRef = doc(db, 'novice', this.state.editId);
    if (this.state.uploadImage) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      result += ".png"
      const storage = getStorage();
      const storageRef = ref(storage, result);
      const metadata = {
        contentType: 'image/jpeg'
      };

      
      const desertRef = ref(storage, this.state.editImageName);
      deleteObject(desertRef).then(() => {
        // File deleted successfully
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });

      const uploadTask = uploadBytesResumable(storageRef, this.state.uploadImage, metadata);

      uploadTask.on('state_changed',
        (snapshot) => {

          /* const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100; */

        },
        (error) => {
          console.log(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

            updateDoc(docRef, {
              image: downloadURL,
              imageName: result,
              text_de: this.state.textNemEdit,
              text_en: this.state.textAngEdit,
              text_si: this.state.textSloEdit,
              title_de: this.state.titleNemEdit,
              title_en: this.state.titleAngEdit,
              title_si: this.state.titleSloEdit
            });
            setTimeout(() => {
              this.getNovice()
              this.setState({ uploadImage: null })
            }, 2000)

          });
        }
      );
    } else {


      await updateDoc(docRef, {
        text_de: this.state.textNemEdit,
        text_en: this.state.textAngEdit,
        text_si: this.state.textSloEdit,
        title_de: this.state.titleNemEdit,
        title_en: this.state.titleAngEdit,
        title_si: this.state.titleSloEdit
      });
      this.getNovice()
    }
    this.setState({ popupEdit: false })
  }

  render() {

    return (
      <>
        <div className={this.state.popup ? "block absolute w-screen h-screen z-30" : "hidden"}>
          <div className="bg-gray-800 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-white px-10 py-10 rounded min-w-[400px]">
            <p className="mt-3 mb-1 text-xs">FOTOGRAFIJA</p>
            {this.state.uploadImage && <img className="max-w-[100px] max-h-[150px] pb-1" alt="Slika stroja" src={URL.createObjectURL(this.state.uploadImage)} />}
            <button className="bg-sky-700 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => { document.getElementById('imageUpload').click() }}>Naložite sliko</button>
            <input className="hidden" id="imageUpload" type="file" name="uploadImage" onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">NASLOV V SLOVENŠČINI</p>
            <input className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="titleSlo" value={this.state.titleSlo} onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">NASLOV V ANGLEŠČINI</p>
            <input className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="titleAng" value={this.state.titleAng} onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">NASLOV V NEMŠČINI</p>
            <input className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="titleNem" value={this.state.titleNem} onChange={this.changeValue} />

            <p className="mt-3 mb-1 text-xs">BESEDILO V SLOVENŠČINI</p>
            <textarea className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="textSlo" value={this.state.textSlo} onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">BESEDILO V ANGLEŠČINI</p>
            <textarea className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="textAng" value={this.state.textAng} onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">BESEDILO V NEMŠČINI</p>
            <textarea className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="textNem" value={this.state.textNem} onChange={this.changeValue} />

            <div className="flex mt-10 justify-between">
              <button className="bg-green-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={this.novaNovica}>Nova novica</button>
              <button className="bg-red-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.setState({ popup: !this.state.popup })}>Prekliči</button>
            </div>
            {this.state.errorMsg && <div className="mt-3 text-sm">{this.state.errorMsg}</div>}
          </div>
          <div className="w-screen h-screen z-10 bg-gray-900 opacity-90" onClick={() => this.setState({ popup: !this.state.popup })} />
        </div>

        <div className={this.state.popupEdit ? "block absolute w-screen h-screen z-30" : "hidden"}>
          <div className="bg-gray-800 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-white px-10 py-10 rounded min-w-[400px]">

            <p className="mt-3 mb-1 text-xs">TRENUTNA FOTOGRAFIJA</p>
            <img className="max-w-[100px] max-h-[150px] pb-1" src={this.state.editImageURL} alt="" />
            <p className="mt-3 mb-1 text-xs">FOTOGRAFIJA</p>
            {this.state.uploadImage && <img className="max-w-[100px] max-h-[150px] pb-1" alt="Slika stroja" src={URL.createObjectURL(this.state.uploadImage)} />}

            <button className="bg-sky-700 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => { document.getElementById('imageUpload').click() }}>Naložite novo sliko</button>
            <input className="hidden" id="imageUpload" type="file" name="uploadImage" onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">NASLOV V SLOVENŠČINI</p>
            <input className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="titleSloEdit" value={this.state.titleSloEdit} onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">NASLOV V ANGLEŠČINI</p>
            <input className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="titleAngEdit" value={this.state.titleAngEdit} onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">NASLOV V NEMŠČINI</p>
            <input className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="titleNemEdit" value={this.state.titleNemEdit} onChange={this.changeValue} />

            <p className="mt-3 mb-1 text-xs">BESEDILO V SLOVENŠČINI</p>
            <textarea className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="textSloEdit" value={this.state.textSloEdit} onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">BESEDILO V ANGLEŠČINI</p>
            <textarea className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="textAngEdit" value={this.state.textAngEdit} onChange={this.changeValue} />
            <p className="mt-3 mb-1 text-xs">BESEDILO V NEMŠČINI</p>
            <textarea className="text-black w-full rounded-lg px-3 py-2 drop-shadow outline-0 text-sm font-normal filter-none" name="textNemEdit" value={this.state.textNemEdit} onChange={this.changeValue} />

            <div className="flex mt-10 justify-between">
              <button className="bg-green-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={this.urediNovica}>Uredi novico</button>
              <button className="bg-red-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.setState({ popupEdit: !this.state.popupEdit })}>Prekliči</button>
            </div>
            {this.state.errorMsg && <div className="mt-3 text-sm">{this.state.errorMsg}</div>}
          </div>
          <div className="w-screen h-screen z-10 bg-gray-900 opacity-90" onClick={() => this.setState({ popupEdit: !this.state.popupEdit })} />
        </div>


        <div className={`${this.props.open ? "ml-48 w-[calc(12rem - 100vw)]" : "ml-10 w-[calc(2.5rem - 100vw)]"} md:ml-48 relative bg-gray-600 w-[calc(12rem - 100vw)] h-screen text-white overflow-auto`}>
          <div className="w-full h-10 bg-gray-800 relative flex justify-between items-center">
            <p className="text-xl ml-5">Novice</p>
            <button className="mr-5 bg-sky-900 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.setState({ popup: !this.state.popup })}>Nova novica</button>
          </div>
          <div className="relative">
            {this.state.novice.map(novica => {

              let date = new Date(novica.date.seconds * 1000)
              return (
                <div className="bg-gray-700 m-5 p-5 rounded-md flex flex-col justify-between">
                  <div className="flex flex-row justify-between gap-5">
                    <img className="max-w-[200px] max-h-[200px] w-full h-full mb-5" src={novica.image} alt="" />


                    <div className="flex flex-col gap-2">
                      <button className="bg-green-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.setState({
                        popupEdit: !this.state.popupEdit,
                        editImageURL: novica.image,
                        editImageName: novica.imageName,
                        editId: novica.id,
                        titleSloEdit: novica.title_si,
                        titleAngEdit: novica.title_en,
                        titleNemEdit: novica.title_de,
                        textSloEdit: novica.text_si,
                        textAngEdit: novica.text_en,
                        textNemEdit: novica.text_de,
                      })}>Uredi novico</button>
                      <button className="bg-red-500 px-3 py-1 rounded font-semibold hover:scale-105 ease-in duration-75 outline-0" onClick={() => this.deleteNovica(novica)}>Izbriši novico</button>

                    </div>

                  </div>

                  <div>
                    <div>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</div>
                    <div className="p-3 bg-gray-600 rounded-md my-5">
                      <p className="mb-2">NASLOV V SLOVENŠČINI</p>
                      <div>{novica.title_si}</div>
                    </div>
                    <div className="p-3 bg-gray-600 rounded-md mb-5">
                      <p className="mb-2">NASLOV V ANGLEŠČINI</p>
                      <div>{novica.title_en}</div>
                    </div>
                    <div className="p-3 bg-gray-600 rounded-md mb-5">
                      <p className="mb-2">NASLOV V NEMŠČINI</p>
                      <div>{novica.title_de}</div>
                    </div>
                    <div className="p-3 bg-gray-600 rounded-md mb-5">
                      <p className="mb-2">BESEDILO V SLOVENŠČINI</p>
                      <div>{novica.text_si}</div>
                    </div>
                    <div className="p-3 bg-gray-600 rounded-md mb-5">
                      <p className="mb-2">BESEDILO V ANGLEŠČINI</p>
                      <div>{novica.text_en}</div>
                    </div>
                    <div className="p-3 bg-gray-600 rounded-md">
                      <p className="mb-2">BESEDILO V NEMŠČINI</p>
                      <div>{novica.text_de}</div>
                    </div>

                  </div>
                </div>
              )


            })}
          </div>
        </div>
      </>
    )
  }
}
