/* --- NPM --- */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import { ChipIcon, NewspaperIcon, ArrowNarrowRightIcon, ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import Logo from '../images/logo.svg';


const navigation = [
    { name: 'Novice', href: '/novice', img: <NewspaperIcon />},
    { name: 'Stroji', href: '/stroji', img: <ChipIcon />},
]

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        
    }

    

    render() {

        return (
            <>
                <div className={`${this.props.open ? "w-48" : "w-10"} md:w-48 md:block bg-gray-800  h-screen top-0 left-0 absolute z-0`}>
                    <div className="flex flex-col space-y-8">
                        <div className="text-white md:hidden" onClick={this.props.openSidebar}>{this.props.open ? <ArrowNarrowLeftIcon className="w-10 h-auto cursor-pointer"/> : <ArrowNarrowRightIcon className="w-10 h-auto cursor-pointer"/>}</div>
                        <img className="w-auto mx-5 mt-10" src={Logo} alt="logo" />


                        {navigation.map((item) => (
                            <NavLink
                                key={item.name}
                                to={item.href}
                                className={(navData) =>
                                    navData.isActive ? `${this.props.open ? "" : "hidden"} text-white bg-gray-900 px-3 py-2 mx-3 rounded-md text-md font-medium hover:bg-gray-700 md:block` : `${this.props.open ? "" : "hidden"} text-gray-300 hover:text-white px-3 py-2 mx-3 rounded-md text-md font-medium hover:bg-gray-700 md:block`
                                }
                            >
                                <div className="flex flex-row items-center justify-left gap-3 mx-5">
                                    <div className="w-6">{item.img}</div>
                                    {item.name}
                                </div>

                            </NavLink>
                        ))}

                    </div>
                </div>
            </>
        )
    }
}

export default Sidebar;
