/* --- NPM --- */
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { app } from '../firebase/firebase-config'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';


export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            redirect: false,
            message: ""
        }
        this.changeInput = this.changeInput.bind(this);
        this.login = this.login.bind(this);
    }
    changeInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    async login() {
        const authentication = getAuth();
        signInWithEmailAndPassword(authentication, this.state.email, this.state.password)
            .then((response) => {
                sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
                this.setState({ redirect: true, message: "" })
            })
            .catch((error) => {
                if (error.code === 'auth/wrong-password') {
                    this.setState({message: "Napačno geslo!"})
                }
                if (error.code === 'auth/user-not-found') {
                    this.setState({message: "Napačen E-mail!"})
                }

            })
    }
    render() {
        if (this.state.redirect) return <Navigate to="/sporocila" />

        return (
            <>
                <div className="bg-gray-800 w-screen h-screen relative">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                        <div>
                            <p>E-mail</p>
                            <input className="mt-3 text-gray-800 px-3 py-1 outline-0" name="email" value={this.state.email} onChange={this.changeInput} />
                        </div>

                        <div className="mt-5">
                            <p>Geslo</p>
                            <input className="mt-3 text-gray-800 px-3 py-1 outline-0" name="password" value={this.state.password} onChange={this.changeInput} />
                        </div>
                        <button className="mt-5 px-3 py-1 bg-white text-gray-800 font-medium outline-0" onClick={this.login}>Vstopi</button>
                        {this.state.message !== "" && <div>{this.state.message}</div>}
                    </div>

                </div>

            </>
        )
    }
}