import { Component } from "react"
import { Navigate } from 'react-router-dom';

import site_loader from '../images/site_loader.svg'
import { withRouter } from "../utils/WithRouter";

class WithAuth extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            authenticated: false
        }

        this.fetchAuth = this.fetchAuth.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) this.fetchAuth()
    }

    async componentDidMount() {
        this.fetchAuth()
    }

    async fetchAuth() {
        let authToken = sessionStorage.getItem('Auth Token')
        if (authToken) {
            this.setState({
                loading: false,
                authenticated: true
            })
        } else {
            this.setState({
                loading: false,
                authenticated: false
            })
        }
    }

    render() {
        const { children } = this.props
        const { loading, authenticated } = this.state;
        if (loading) return (
            <div className="relative w-screen h-screen">
                <img className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" src={site_loader} />
            </div>

        )

        if (authenticated) {
            if (window.location.href.includes("login")) {
                return <Navigate to="/novice" replace />
            } else {
                return children;
            }
        } else {
            if (window.location.href.includes("login")) {
                return children
            } else {
                return <Navigate to="/login" replace />
            }
        }
    }
}

export default withRouter(WithAuth)